<template>
  <v-text-field
    :label="label"
    :value="value"
    type="password"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
